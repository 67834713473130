import { useEffect, useRef, useCallback } from 'react';

const useScrollRotation = (maxRotation: number = 40) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((): void => {
    if (elementRef.current && wrapperRef.current) {
      const scrollY: number = window.scrollY;
      const totalScrollHeight: number = wrapperRef.current.scrollHeight - window.innerHeight;

      // Calculate rotation based on scroll position
      const rotationPercentage: number = Math.min(scrollY / totalScrollHeight, 1);
      const currentRotation: number = rotationPercentage * maxRotation * 2 - maxRotation;

      // Apply rotation
      elementRef.current.style.transform = `translate(-50%, -50%) rotateY(${currentRotation}deg)`;
    }
  }, [maxRotation]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return { elementRef, wrapperRef };
};

export default useScrollRotation;
