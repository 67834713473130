import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.jpg'; // Import the logo
import WaitlistForm from './WaitlistForm';

const Header: React.FC = () => {
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);

  const handleWaitlistOpen = () => {
    setIsWaitlistOpen(true);
  };

  const handleWaitlistClose = () => {
    setIsWaitlistOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Morph Logo" />
        </Link>
      </div>
      <nav>
        <ul>
          <li>
            <button className="demo-button" onClick={handleWaitlistOpen}>
              Meet With Us
            </button>
          </li>
        </ul>
      </nav>
      {isWaitlistOpen && <WaitlistForm onClose={handleWaitlistClose} />}
    </header>
  );
};

export default Header;