import React, { useState } from 'react';
import './WaitlistForm.css';

interface WaitlistFormProps {
  onClose: () => void;
}

const WaitlistForm: React.FC<WaitlistFormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    background: ""
  });

  const [status, setStatus] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus('Sending...');

    try {
      const response = await fetch('/.netlify/functions/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const result = await response.json();
        setStatus(result.message);
        setFormData({ firstName: '', lastName: '', phoneNumber: '', email: '', background: '' });
        onClose();
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
    // console.error('Error:', error);
      setStatus('Failed to send waitlist request. Please try again.');
    }
  };

  return (
    <div className="waitlist-form-overlay">
      <div className="waitlist-form-container">
        <h2>Meet With Us!</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <textarea
            name="background"
            value={formData.background}
            onChange={handleChange}
            placeholder='What brings you to explore our solutions?'
            required
            ></textarea>
          <button type="submit" className="cta-button">Submit</button>
        </form>
        {status && <p className="status-message">{status}</p>}
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default WaitlistForm;
